import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import '../css/Contact.css';

const Contact = () => {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);

	return (
		<section id='contact' className='contact-section' data-aos='fade-up'>
			<div className='container'>
				<h2 className='contact-heading' data-aos='fade-up'>
					Kontakt
				</h2>
				<div className='contact-content'>
					<div className='contact-details' data-aos='fade-up' data-aos-delay='200'>
						<h3>Dane kontaktowe</h3>
						<p>
							<FaMapMarkerAlt className='contact-icon' />
							Sportowa 4/16/2, 55-200 Oława
						</p>
						<p>
							<FaPhone className='contact-icon' />
							<a href='tel:+48692250486'>+48 692 250 486</a>
						</p>
						<p>
							<FaEnvelope className='contact-icon' />
							<a href='mailto:kontakt@gabinet.com'>kontakt@psychoterapia-aldona.pl</a>
						</p>
						<p>
							<strong>Godziny otwarcia:</strong> Pon-Czw: 15:00 - 19:00
						</p>
					</div>
					<div className='contact-map' data-aos='fade-up' data-aos-delay='400'>
						<iframe
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.204384487669!2d17.292160615487447!3d51.04115647956266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470f1675b54c1499%3A0x71bd7c676cf65acb!2sSportowa%204%2C%2055-200%20O%C5%82awa!5e0!3m2!1spl!2spl!4v1695214839237!5m2!1spl!2spl'
							width='100%'
							height='400'
							allowFullScreen=''
							loading='lazy'
							referrerPolicy='no-referrer-when-downgrade'
							title='Location Map'></iframe>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
