import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/HelpSection.css'; // Stylizacja dla sekcji Komu Pomagam

const HelpSection = () => {
	useEffect(() => {
		AOS.init({ duration: 1000 }); // Inicjalizacja AOS z czasem trwania 1s
	}, []);

	return (
		<section id='komuPomagam' className='help-section' data-aos='fade-up'>
			<div className='container'>
				<h2 className='help-heading' data-aos='fade-up'>
					Kiedy mogę pomóc
				</h2>
				<div className='help-grid'>
					<div className='help-tile' data-aos='fade-up' data-aos-delay='200'>
						gdy odczuwasz smutek, przygnębienie, pustkę lub jesteś rozdrażniona/y bez wyraźnej przyczyny,
					</div>
					<div className='help-tile' data-aos='fade-up' data-aos-delay='400'>
						wciąż się czymś martwisz, czegoś się boisz, jesteś napięta/y i niespokojna/y,
					</div>
					<div className='help-tile' data-aos='fade-up' data-aos-delay='600'>
						przeżywasz kryzys w związku lub masz trudności w relacjach z innymi osobami,
					</div>
					<div className='help-tile' data-aos='fade-up' data-aos-delay='800'>
						doświadczasz stresu związanego z nauką lub pracą,
					</div>
					<div className='help-tile' data-aos='fade-up' data-aos-delay='1000'>
						przeżyłaś/eś rozstanie lub żałobę i mimo upływającego czasu nadal cierpisz,
					</div>
					<div className='help-tile' data-aos='fade-up' data-aos-delay='1200'>
						chcesz lepiej poznać siebie i/lub poprawić jakość swojego życia,
					</div>
					<div className='help-tile' data-aos='fade-up' data-aos-delay='1400'>
						odczuwasz różne dolegliwości mimo wykluczenia przyczyn medycznych,
					</div>
					<div className='help-tile' data-aos='fade-up' data-aos-delay='1600'>
						odczuwasz problemy egzystencjalne (lęk przed śmiercią, osamotnienie)
					</div>
				</div>
				<h3 className='help-extra' data-aos='fade-up' data-aos-delay='1700'>
					I w wielu innych sytuacjach, których nie wymieniłam.
				</h3>
			</div>
		</section>
	);
};

export default HelpSection;
