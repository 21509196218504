import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../css/NavigationBar.css';

const NavigationBar = () => {
	const [navbarBg, setNavbarBg] = useState(false);
	const [activeSection, setActiveSection] = useState('#home'); // Track the active section

	useEffect(() => {
		AOS.init({ duration: 1000, once: true }); // Initialize AOS with a 1000ms duration
	}, []);

	// Handle background color change on scroll
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100) {
				setNavbarBg(true); // Turn navbar background solid after scrolling
			} else {
				setNavbarBg(false); // Transparent navbar at the top
			}

			// Update active section based on scroll position
			const sections = ['#home', '#oferta', '#rules', '#contact'];
			const scrollPosition = window.scrollY + window.innerHeight / 2;

			for (let i = sections.length - 1; i >= 0; i--) {
				const sectionElement = document.querySelector(sections[i]);
				if (sectionElement && sectionElement.offsetTop <= scrollPosition) {
					setActiveSection(sections[i]);
					break;
				}
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Navbar
			className={`navbar-custom ${navbarBg ? 'navbar-solid' : ''}`}
			fixed='top'
			expand='lg'
			data-aos='fade-down' // AOS animation for navbar
		>
			<Container>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ms-auto'>
						<Nav.Link href='#home' className={activeSection === '#home' ? 'active' : ''} data-aos='fade-right'>
							HOME
						</Nav.Link>
						<Nav.Link
							href='#oferta'
							className={activeSection === '#oferta' ? 'active' : ''}
							data-aos='fade-right'
							data-aos-delay='200'>
							OFERTA
						</Nav.Link>
						<Nav.Link
							href='#rules'
							className={activeSection === '#rules' ? 'active' : ''}
							data-aos='fade-right'
							data-aos-delay='400'>
							ZASADY
						</Nav.Link>
						<Nav.Link
							href='#contact'
							className={activeSection === '#contact' ? 'active' : ''}
							data-aos='fade-right'
							data-aos-delay='600'>
							KONTAKT
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default NavigationBar;
