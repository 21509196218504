import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/AboutMeSection.css'; // Stylizacja dla sekcji O Mnie
import { FaCheck } from 'react-icons/fa';

const AboutMeSection = () => {
	useEffect(() => {
		AOS.init({ duration: 1200 });
	}, []);
	return (
		<section id='oMnie' className='aboutme-section' data-aos='fade-up'>
			<div className='container'>
				<div className='aboutme-content'>
					<div className='aboutme-text'>
						<h2 className='aboutme-heading'>O Mnie</h2>
						<p>
							Nazywam się Aldona Furgała. Jestem psychoterapeutą w trakcie szkolenia, pedagogiem i doradcą zawodowym.
						</p>
						<p>
							Od blisko 5 lat kształcę się jako psychoterapeuta w Polskim Instytucie Psychoterapii Integratywnej MKN w
							Krakowie, akredytowanym przez European Association for Integrative Psychotherapy (EAIP), Polską Federację
							Psychoterapii (PFP) oraz Polskie Towarzystwo Psychoterapii Integratywnej i Systemowej (PTPIiS). Obecnie
							jestem w trakcie II stopnia szkolenia PROFI.
						</p>
						<p>Jestem członkiem Polskiego Towarzystwa Psychoterapii Integratywnej i Systemowej.</p>
						<p>
							Pracuję w podejściu integratywnym systemowym, które łączy wiele nurtów psychoterapeutycznych. Umożliwia
							ono indywidualne podejście do problemów każdego pacjenta oraz lepsze ich rozumienie.
						</p>
						<p>Swoją pracę poddaję regularnej superwizji.</p>
						<p>Stale poszerzam swoje kompetencje, uczestnicząc w szkoleniach z zakresu psychoterapii.</p>
						<p>Ukończyłam szkolenia:</p>
						<ul className='aboutme-list'>
							<li>
								<FaCheck className='icon' /> „Psychoterapia par- praca Psychoterapeuty w służbie miłości”
							</li>
							<li>
								<FaCheck className='icon' /> „Diagnoza i integratywna psychoterapia eksploracyjno-afirmatywna osób
								kwestionujących płeć”
							</li>
							<li>
								<FaCheck className='icon' /> „Integratywna systemowa psychoterapia zaburzeń depresyjnych”
							</li>
							<li>
								<FaCheck className='icon' /> „Integratywna systemowa psychoterapia zaburzeń lękowych i
								posttraumatycznych”
							</li>
							<li>
								<FaCheck className='icon' /> „DSM-IV-TR i nowości w DSM-5”
							</li>
							<li>
								<FaCheck className='icon' /> „Integratywna systemowa psychoterapia krótkoterminowa”
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutMeSection;
