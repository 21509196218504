import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Offer.css'; // Stylizacja dla sekcji Oferta

const Offer = () => {
	useEffect(() => {
		AOS.init({ duration: 1000 }); // Inicjalizacja AOS
	}, []);

	return (
		<section id='oferta' className='oferta-section' data-aos='fade-up'>
			<div className='container'>
				<h2 className='oferta-heading' data-aos='fade-up'>
					Oferta
				</h2>
				<div className='oferta-cards'>
					<div className='oferta-card' data-aos='fade-up' data-aos-delay='400'>
						<h3>Konsultacja psychoterapeutyczna</h3>
						<p>150 zł (do 50 min)</p>
					</div>
					<div className='oferta-card' data-aos='fade-up' data-aos-delay='200'>
						<h3>Psychoterapia indywidualna</h3>
						<p>150 zł (do 50 min)</p>
					</div>

					<div className='oferta-card' data-aos='fade-up' data-aos-delay='600'>
						<h3>Psychoterapia par / małżeństw</h3>
						<p>200 zł (do 75 min)</p>
					</div>
				</div>
				<p className='oferta-text' data-aos='fade-up' data-aos-delay='800'>
					Prowadzę psychoterapię indywidualną i psychoterapię par.
				</p>
				<p className='oferta-text' data-aos='fade-up' data-aos-delay='800'>
					Jeśli szukasz pomocy i zainteresowała Cię oferta mojego gabinetu, zapraszam Cię do kontaktu.
				</p>
				<a href='#contact' className='btn btn-contact' data-aos='fade-up' data-aos-delay='1000'>
					Kontakt
				</a>
			</div>
		</section>
	);
};

export default Offer;
