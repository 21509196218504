import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; // Import an icon
import '../css/Rules.css'; // Custom styles for the Rules section

const Rules = () => {
	return (
		<section id='rules' className='rules-section'>
			<div className='container'>
				<h2 className='rules-heading'>Zasady Gabinetu</h2>
				<div className='rules-grid'>
					<div className='rules-column'>
						<div className='rules-item'>
							<p>
								Konsultacja i sesja indywidualna trwają do 50 min/ sesja terapii par do 75 min. Odpłatność za sesje
								realizowana jest bezpośrednio po zakończeniu spotkania (płatność gotówką lub blikiem).
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>
								Sesje odbywają się zwykle raz w tygodniu. W szczególnych sytuacjach możliwa jest zmiana częstotliwości
								spotkań.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>
								W okresie psychoterapii nie można pozostawać w terapii u innego terapeuty, chyba że terapeuta prowadzący
								zdecyduje inaczej.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>
								W celu prowadzenia skutecznej terapii, pacjent jest zobowiązany do informowania terapeuty na bieżąco o
								tym, co aktualnie przeżywa i o znaczących faktach ze swojego życia.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>
								Czas trwania, cele i zasady terapii są uzgadniane indywidualnie z psychoterapeutą w formie ustnego
								kontraktu za obopólną zgodą.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>W przypadku spóźnienia, pacjent płaci za całą sesję i sesja nie jest przedłużana.</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
					</div>
					<div className='rules-column'>
						<div className='rules-item'>
							<p>
								W przypadku odwołania sesji, pacjent jest zobowiązany poinformować psychoterapeutę najpóźniej 24 godziny
								przed umówionym spotkaniem, inaczej ponosi koszt sesji.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>
								W przypadku przerwy w terapii wynikającej z planowanego urlopu bądź niedyspozycji psychoterapeuty,
								pacjenci będą informowani o tym fakcie najszybciej jak to będzie możliwe.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>
								Psychoterapeuta jest zobowiązany do zachowania w tajemnicy wszystkich informacji pochodzących z terapii
								oraz samego faktu korzystania przez pacjenta z terapii wobec osób trzecich. Wyjątkiem jest sytuacja
								zagrożenia życia lub zdrowia pacjenta albo osób trzecich, lub sytuacja określona prawem.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>
								Praca psychoterapeuty podlega stałej superwizji. Służy to ulepszeniu usług i lepszej opiece nad
								pacjentami. Omawiając ją z innymi terapeutami, psychoterapeuta dba o anonimowość pacjentów.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
						<div className='rules-item'>
							<p>
								Psychoterapeuta współpracując z innymi specjalistami leczącymi pacjenta (np. psychiatrą), udostępnia
								tylko niezbędne informacje za wiedzą pacjenta.
							</p>
							<FaCheckCircle className='rules-icon-bottom' />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Rules;
