import React from 'react';
import NavigationBar from './components/NavigationBar';
import Home from './components/Home';
import Oferta from './components/Offer';
import Rules from './components/Rules';
import HelpSection from './components/HelpSection';
import AboutMePage from './components/AboutMePage';
import Contact from './components/Contact';

function App() {
	return (
		<div>
			<NavigationBar />
			<Home />
			<HelpSection />
			<AboutMePage />
			<Oferta />
			<Rules />
			<Contact />
		</div>
	);
}

export default App;
