import React from 'react';
import '../css/Home.css';

const Home = () => {
	return (
		<section id='home' className='home-section'>
			<div className='overlay'>
				<div className='content'>
					<h1 className='heading'>GABINET PSYCHOTERAPII INTEGRATYWNEJ</h1>
					<h2 className='subheading' style={{ fontFamily: "'Dancing Script', cursive" }}>
						Aldona Furgała
					</h2>
				</div>
			</div>
		</section>
	);
};

export default Home;
